<template>
	<a-layout id="components-layout-demo-side" style="min-height: 100vh">
	  <a-layout-sider v-model="collapsed" collapsible v-if="!isAgent">
	    <!-- <div class="logo">
			<img :style="collapsed?'width:48px':'width:100%'" :src="collapsed ? require('../assets/mini-logo.png') : require('../assets/logo.png')" />
		  </div> -->
	    <a-menu theme="dark" :default-selected-keys="['2']" :default-open-keys="['sub1']" mode="inline">
	      <a-sub-menu key="sub1">
	        <span slot="title"><a-icon type="menu" /><span>工具箱</span></span>
	        <a-menu-item @click="toBarcode" key="1">
	         生成条码
	        </a-menu-item>
			  <a-menu-item key="2">
			   历史记录
			  </a-menu-item>
	      </a-sub-menu>
		  <a-menu-item key="sub2" v-if="isweb" @click="downloadPC">
		    <a-icon type="windows" />
		    <span class="nav-text">PC端下载</span>
		  </a-menu-item>
	    </a-menu>
	  </a-layout-sider>
	  <a-layout v-if="!isAgent">
	    <!-- <a-layout-header style="background: #fff; padding: 0" /> -->
	    <a-layout-content style="margin: 0 16px">
	      <a-breadcrumb style="margin: 16px 0">
	        <a-breadcrumb-item>工具箱</a-breadcrumb-item>
	        <a-breadcrumb-item>历史记录</a-breadcrumb-item>
	      </a-breadcrumb>
	      <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
				<a-row>
				  <a-divider>只显示最近20次生成记录</a-divider>
				  <div style="background:#ECECEC; padding:30px">
						<a-empty description="暂无数据" v-if="achelist.length == 0"/>
						<a-row :gutter="24" class="history-arow">
				           <a-col :span="8" v-for="(item, index) in achelist" v-bind:item="item" v-bind:index="index" v-bind:key="item+index">
				             <a-card size="small" :title="item.time" :bordered="false">
							    <template slot="actions" class="ant-card-actions">
							         <a-icon key="ellipsis" type="ellipsis" @click="findAll(index)"/>
									 <a @click="makeBarcode(index)">一键生成条码</a>
							    </template>
								<a-card-meta>
								  <template slot="description">
									<span v-for="(a, b) in item.barr[0].slice(0,10)" v-bind:item="a" v-bind:index="b" v-bind:key="a+b">
										{{a}},
									</span>
								  </template>
								</a-card-meta>
				             </a-card>
				           </a-col>
				        </a-row>
				  </div>
				</a-row>
	      </div>
	    </a-layout-content>
	    <a-layout-footer style="text-align: center">
			<span>© 2009-2021 ddtool.cn 版权所有</span>
			<br/>
	     <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19037097号</a>
	    </a-layout-footer>
		  <a-back-top />
	  </a-layout>
	  <a-result status="warning" title="不支持移动设备访问" v-if="isAgent">
	    <template #extra>
	  	<span>请在PC浏览器访问当前网址</span>
	    </template>
	  </a-result>
	</a-layout>
</template>

<script>
	export default {
	  data() {
	    return {
	      collapsed: false,
		  achelist:[],
		  isweb:false,
		  isAgent:false
	    };
	  },
	  mounted() {
		  if(this.$common.isAgent()){
		  	this.isAgent = true;
		  }
		  if(localStorage.getItem("aches")) {
		  	this.achelist = JSON.parse(localStorage.getItem("aches"));
		  }
		  if(location.hostname == "deppon.ddtool.cn"){
		  	this.isweb = true;
		  }
	  },
	  methods: {
		  toBarcode(){
			  this.$router.push("/");
		  },
		  toSinajump(){
		  	this.$router.push("sinajump")
		  },
		  findAll(index){
			 // console.log(this.achelist[index]['barr'][0]);
			 var successHtml = [];
			 const h = this.$createElement;
			 this.achelist[index]['barr'][0].forEach(function(item){
			 	successHtml.push(h('span', item+'，'));
			 });
			 if(successHtml.length === 0){
			 	successHtml = "无";
			 }
			 this.$success({
			 	title: '条码内容',
			 	content:h('div', {}, successHtml),
			 });
		  },
		  makeBarcode(index){
			  this.$router.push("/?id="+index);
		  },
		  downloadPC(){
			  window.open('http://deppon.ddtool.cn/windows/win32-x64-1.0/%E5%BE%B7%E9%82%A6%E5%B0%8F%E5%8A%A9%E6%89%8B.exe');
		  }
	  },
	  
	}
</script>

<style>
	.history-arow .ant-col{
		margin-bottom: 20px;
	}
	.ant-card-meta-detail{
		height: 105px;
	}
</style>
