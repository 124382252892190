/**
 * 判断客户端终端
 */
const isAgent = function(){
	var u = navigator.userAgent, app = navigator.appVersion;
	var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //如果输出结果是true就判定是android终端或者uc浏览器
	var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //根据输出结果true或者false来判断ios终端
	if(isAndroid || isIOS){
		return true;
	}else{
		return false;
	}
}

const IEVersion = function() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
      var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
      var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
      if(isIE) {
          return true;
      } else if(isEdge) {
          return true;
      } else if(isIE11) {
          return true; 
      }else{
          return false;//不是ie浏览器
      }
  }

export default {
	isAgent,
	IEVersion
}
