import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import history from '../views/history.vue'
Vue.use(VueRouter)
const routes = [
	{
	    path: '/', //当为'/'时，启动项目时为默认页面
	    component: index,
		meta: {
			title: '东南方-生成条码'
		}
	},
	{
	    path: '/history', 
	    component: history,
		meta: {
			title: '东南方-历史记录'
		}
	}
]
var router =  new VueRouter({
    routes
	// mode:"history"    // mode 设置为history ，去掉地址栏上的 # 号
})
export default router

