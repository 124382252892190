import Vue from 'vue'
import App from './App.vue'
import router from "./routes/index.js"
import common from './components/common'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.config.productionTip = false;
Vue.use(Antd);
Vue.prototype.$common = common;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
	document.title = to.meta.title
  }
  if (to.meta.requireAuth) { // 查看请求的页面否需要认证
	return
  } else { // 不需要认证的全部直接放行
    next()
    return
  }
})
/* eslint-disable no-new */
new Vue({
	router,
	components: { App },
	render: h => h(App)
}).$mount('#app')
